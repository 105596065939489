










import { Coupon } from '@shared/models/coupon';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MyCoupons extends Vue {
  coupons: Coupon[] = [];
  @Prop() private msg!: string;

  created() {
    this.$store.dispatch('coupon/getAllSecondPartner')
      .then((res) =>  this.coupons = res.data,
      (err) => console.log(err));
  }
}
